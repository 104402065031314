import React, { Component } from "react";
import Layout from "../components/core/Layout";
import SEO from "../components/core/Seo";
import get from 'lodash/get';
import Hero from '../components/futures/Hero';
import IntroBlock from "../components/futures/Intro";
import PrivateRoute from "../router/PrivateRoute"

class FuturesPage extends Component {
  render() {
    const path = this.props.location.pathname;
    const data = get(this, 'props.data');
    const {
      page,
      hero,
      introImage1,
      introImage2
    } = data;

    return (
      <Layout>
        <SEO title={page.metaTitle}
             description={page.metaDescription}
             fullTitle={false}
             path={path}/>
        <Hero hero={hero}/>
        <IntroBlock introImage1={introImage1} introImage2={introImage2}/>
      </Layout>
    )
  }
}

function futures(props) {
  return <PrivateRoute component={FuturesPage} {...props}/>
}

export default futures;


export const pageQuery = graphql`
  query FuturesPageQuery {
    page: contentfulLandingPage(slug: { eq: "futures" }) {
      id
      metaTitle
      metaDescription
    }
    hero: file(relativePath: { eq: "futures/hero-background.jpg" }) {
        childImageSharp {
            fluid(quality: 80, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    introImage1: file(relativePath: { eq: "futures/intro-opt-01.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    introImage2: file(relativePath: { eq: "futures/intro-opt-02.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 960) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

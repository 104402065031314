import React from "react";
import styled from "styled-components";
import fonts from "../../styles/fonts"
import { Row, Col, Container } from "styled-bootstrap-grid";
import Img from "gatsby-image";
import WavesImg from "../../resources/images/icons/waves.svg";
import BackgroundImg from "../../resources/images/futures/intro-background.jpg";
import BackgroundImgMobile from "../../resources/images/futures/intro-background-mobile.jpg";
import Fade from "react-reveal/Fade";

const IntroBackground = styled.div`
    width: 100%;
    background: #000914;
    padding-left: 0;
    padding-right: 0;
    overflow: hidden;
`;

const IntroImageBackground = styled.div`
    position: relative;
    background: url(${BackgroundImgMobile}) no-repeat;
    height: 859px;
    margin-top: -800px;
    background-position-y: 100%;
    background-size: cover;
    z-index: 0;
    @media(min-width: 768px) {
        width: 100vw;
        min-height: 621px;
        background: url(${BackgroundImg}) no-repeat;
        background-position-y: 88%;
        background-size: cover;
    }
`;

const ContentContainer = styled(Container)`
    padding: 0 0 0 0;
`;

const StyledRow = styled(Row)`
    margin-left: 0;
    margin-right: 0;
    padding-left: 33px;
    padding-right: 33px;    
    @media(min-width: 768px){
        padding-left: 0;
        padding-right: 0;    
    }
`;

const ColStyled = styled(Col)`
    padding-left: 0;
    padding-right: 0;
    z-index: 1;
`;

const GreyText = styled.div`
    ${fonts.gilroyRegular};
    color: #9CA8B5;
    font-size: 15px;
    line-height: 21px;
`;

const TextBoxFirst = styled.div`
        padding: 0 33px;
    @media(min-width: 992px){
        max-width: 458px;
        margin-top: 71px;
        padding-left: 93px;
    }
`;

const TextBoxSecond = styled.div`
    margin-bottom: 60px;
    padding: 0 33px;
    @media(min-width: 992px){
        max-width: 458px;
        padding: 0 0 45px 93px;
    }
`;

const TextBoxThird = styled.div`
    width: auto;
    @media(min-width: 992px){
        padding-left: 112px;
        padding-top: 68px;
        max-width: 336px;
    }
`;

const BoldText = styled.div`
    ${fonts.swissBlackExtended};
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 1.3px;
    color: #FFFFFF;
    @media(min-width: 992px){
        font-size: 28px;
        line-height: 34px;
        letter-spacing: 1.8px;
    }
`;

const FirstBoldBox = styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 0 33px;
    @media(min-width: 992px){
        margin-top: 35px;
        margin-bottom: 35px;
        max-width: 650px;
        padding: 0;
    }
`;

const SecondBoldBox = styled.div`
    margin-top: 60px;
    margin-bottom: -13%;
    @media(min-width: 992px){
        margin-top: 40px;
        margin-bottom: 40px;
        max-width: 540px;
        margin-left: -20%;
    }
`;

const BottomTextBox = styled.div`
    text-align: center;
    margin-bottom: 100px;
    z-index: 1;
    @media(min-width: 992px){
        padding: 0 121px;
    }
`;

const IntroImageFirst = styled(Img)`
    overflow: hidden;
    width: 452px;
    height: 350px;
    margin-bottom: 40px;
    margin-right: 0;
    margin-left: 35px;
    @media(min-width: 992px){
      margin-left: 55px;
      margin-right: 0;
      height: 655px;
      width: 845px;
    }
`;

const IntroImageSecond = styled(Img)`
    width: 302px;
    height: auto;
    float: right;
    margin-bottom: 100px;
    @media(min-width: 768px){
        width: 450px;
        float: right;
        margin-bottom: 189px;
    }
    @media(min-width: 992px){
        width: 646px;
        float: left;
        margin-bottom: 189px;
    }
`;

const Waves = styled.img`
    position: absolute;
    z-index: 1;
    top: 44%;
    right: 75%;
    @media(min-width: 576px){
        right: 55%
    }
    @media(min-width: 768px){
        right: 57%;
    }
    @media(min-width: 992px){
        top: 14%;
        left: -6%;
    }
`;

const StyledLink = styled.a`
    cursor:pointer;
    border-bottom: 3px solid #9DDCE2;
    color: white;
    text-decoration: none;
`;

const StyledFirstRow = styled(Row)`
    padding: 0;
    margin: 0;
`;

const FirstRowContainer = styled(Container)`
    margin: auto;
    padding: 0;
    @media(min-width: 992px){
        margin: auto;
    }
`;

const IntroBlock = ({ introImage1, introImage2 }) => {
  return (
    <IntroBackground>
      <FirstRowContainer>
        <StyledFirstRow>
          <ColStyled lg={7}>
            <TextBoxFirst>
              <Fade duration={750} delay={500}>
                <GreyText>
                  Here at Lost At Sea we are serious about our beer, really serious. But we also believe that what
                  makes
                  our
                  beer special are the people that make it and the lifestyles we lead. It was this desire to lead a
                  less
                  ordinary life which started us on this voyage and one we hope will last a lifetime.
                </GreyText>
              </Fade>
            </TextBoxFirst>
            <FirstBoldBox>
              <Fade duration={750} delay={750}>
                <BoldText>
                  Living and working on the rugged North Wales, Peninsula allows us to enjoy a pretty special way
                  of
                  life...
                </BoldText>
              </Fade>
            </FirstBoldBox>
            <TextBoxSecond>
              <Fade duration={750} delay={500}>
                <GreyText>
                  From surfing the September swells or drinking in the brewery bar on a Friday afternoon to walking
                  the
                  coast path on a wild winter morning with the dogs.
                </GreyText>
              </Fade>
            </TextBoxSecond>
          </ColStyled>
          <ColStyled lg={5}>
            <Fade bottom duration={1000} delay={250}>
              <IntroImageFirst fluid={introImage1.childImageSharp.fluid} alt={"Intro Image"}/>
            </Fade>
          </ColStyled>
        </StyledFirstRow>
      </FirstRowContainer>
        <ContentContainer>
          <StyledRow>
            <ColStyled lg={7} xsOrder={2} smOrder={2} lgOrder={1}>
              <Waves src={WavesImg}/>
              <Fade bottom duration={1000} delay={250}>
                <IntroImageSecond fluid={introImage2.childImageSharp.fluid} alt={"Intro Image"}/>
              </Fade>
            </ColStyled>
            <ColStyled lg={5} xsOrder={1} smOrder={1} lgOrder={2}>
              <TextBoxThird>
                <Fade duration={750} delay={500}>
                  <GreyText>
                    Combine that with the fact that we get to make something we truly love everyday means we are a
                    pretty
                    happy bunch. Beer has a massive part to play in our lives; we spend more time making it, researching
                    it
                    and drinking it than we do anything else.
                  </GreyText>
                </Fade>
              </TextBoxThird>
              <SecondBoldBox>
                <Fade duration={750} delay={750}>
                  <BoldText>
                    We strive to make better beer, drink better beer and live a better life.
                  </BoldText>
                </Fade>
              </SecondBoldBox>
            </ColStyled>
          </StyledRow>
          <StyledRow>
            <BottomTextBox>
              <BoldText>
                If you’re one of life’s adventurers and would like to join our crew, please <StyledLink href={"mailto:crew@lostatsea.co.uk"}>drop us a line</StyledLink>.
              </BoldText>
            </BottomTextBox>
          </StyledRow>
        </ContentContainer>
      <IntroImageBackground/>
    </IntroBackground>
  )
}


export default IntroBlock

